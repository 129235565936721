import { Fragment, useEffect, useState } from "react";
import mall from '../../src/assets/images/Mall.png'
import retail from '../../src/assets/images/retail.png'
import automotive from '../../src/assets/images/automotive.png'
import toparrow from '../../src/assets/images/svg/top-arrow.svg'
import Counter from "./Counter";
import { filter_hashtag } from "../components/utilits";

const counts = [
  { name: "Trainings", value: 219 },
  { name: "Written Book", value: 48 },
  { name: "Listeners", value: 527 },
  { name: "Own Projects", value: 12 },
];

const skills = [
  { name: "Figma", value: 90, icon: "img/svg/figma.svg" },
  { name: "After Effect", value: "80", icon: "img/svg/Ae.svg" },
  { name: "Photoshop", value: "85", icon: "img/svg/photoshop.svg" },
  { name: "XD", value: "95", icon: "img/svg/Xd.svg" },
  { name: "Illustrator", value: "90", icon: "img/svg/illustrator.svg" },
  { name: "Indesign", value: "75", icon: "img/svg/Id.svg" },
];

const experiences = [
  {
    company: "Bauen Software Inc.",
    designation: "Product Designer",
    time: "2021 - Present",
  },
  {
    company: "Bauen Software Inc.",
    designation: "UX Designer",
    time: "2020 - 2021",
  },
  {
    company: "Bauen Software Inc.",
    designation: "UI Designer",
    time: "2018 - 2020",
  },
  {
    company: "Bauen Software Inc.",
    designation: "Web Designer",
    time: "2016 - 2018",
  },
];

const educations = [
  {
    institution: "Stanford Univercity",
    certificate: "Web Design Course",
    time: "2021 - Present",
  },
  {
    institution: "Art Univercity of New York",
    certificate: "Art Director Course",
    time: "2020 - 2021",
  },
  {
    institution: "Amazon College",
    certificate: "IOS Development",
    time: "2018 - 2020",
  },
  {
    institution: "Univercity of Texas",
    certificate: "UX Expert",
    time: "2017 - 2018",
  },
];

const IndustriesWeServe = () => {
  const [activeTab, setActiveTab] = useState(1);
  const activeContentTab = (value) => (activeTab == value ? "current" : "");

  useEffect(() => {
    filter_hashtag();
  }, [activeTab]);

  return (
    <Fragment>
      <div className="orido_tm_section">
        <div className="orido_tm_informations">
          <div className="container">
            <div className="informations_inner">
              <div className="left">
                <div className="orido_tm_main_title">
                  <h3>
                    <span>
                    Industries We 
                      <br />
                      Serve
                    </span>
                  </h3>
                </div>
                <div className="text">
                  {activeTab === 1 ?
                  <div className="fade-in-image">
                 <img src={mall}  className="fade-in-image"/>
                 </div>
                 : activeTab === 2 ? 
                //  <div className="fade-in-image">
                 <img src={retail}  className="fade-in-image"/>
                //  </div>
                 :
                 <div className="fade-in-image">
                 <img src={automotive}  className="fade-in-image"/>
                 </div>
                  }
                </div>
                
              </div>
              <div className="right">
                <div className="filter">
                  <ul>
                    <li>
                      <a
                        className={`c-pointer ${activeContentTab(1)}`}
                        onClick={() => setActiveTab(1)}
                        data-tab="tab_1"
                      >
                        <span>Malls</span>{" "}
                        <img
                          className="svg"
                          src={toparrow}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        className={`c-pointer ${activeContentTab(2)}`}
                        onClick={() => setActiveTab(2)}
                        data-tab="tab_2"
                      >
                        <span>Retail</span>{" "}
                        <img
                          className="svg"
                          src={toparrow}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        className={`c-pointer ${activeContentTab(3)}`}
                        onClick={() => setActiveTab(3)}
                        data-tab="tab_3"
                      >
                        <span>Automotive</span>{" "}
                        <img
                          className="svg"
                          src={toparrow}
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                  <span className="ccc" />
                </div>
                <div className="content">
                  <div id="tab_1" className={`wrapper ${activeContentTab(1)}`}>
                    <div className="skillbox">
                    <p> 
                    Elevate your mall's revenue potential with swift and accurate foot traffic analysis coupled with efficient sales data capture. Our state-of-the-art mall technology enables you to monitor sales figures and precisely count the number of visitors within your establishment.
                    </p>
                    </div>
                  </div>
                  <div id="tab_2" className={`wrapper ${activeContentTab(2)}`}>
                    <div className="timelinebox">
                     <p>
                     Our retail people counters deliver in-depth customer metrics, precise counts, and insights into customer traffic behavior. They are also highly dependable, convenient, and adaptable for use in various retail settings.
                     </p>
                    </div>
                  </div>
                  <div id="tab_3" className={`wrapper ${activeContentTab(3)}`}>
                    <div className="timelinebox">
                      <p>
                      With our solutions, you can enhance operational efficiency and effectiveness, identify the highest traffic areas in your car dealership, and tailor marketing campaigns to align with your customers' behavior trends.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </Fragment>
  );
};
export default IndustriesWeServe;
