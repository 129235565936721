import React from 'react'
import element from '../../src/assets/images/svg/element.svg'

function Termsandconditions() {
  return (
          <div className="" >
            <div className="orido_tm_about" style={{paddingBottom:0,marginBottom:0,backgroundColor:'white'}}>
              <div className="about_in">
                {/* <div className="left">
                  <div className="box">
                    <h3 className="year">10</h3>
                    <span className="experience">Years of Experience</span>
                    <h3 className="name">John Smith</h3>
                  </div>
                </div> */}
                <div className="right">
                  <span className="element">
                    <img className="svg" src={element} alt="" />
                  </span>
                  <div className="orido_tm_main_title">
                    <h3>
                      <span style={{color:'#FFCD29'}}>Privacy Policy</span>
                    </h3>
                  </div>
                  <div className="text" >
                   
                   <h5 style={{marginBottom:'10px'}}>Introduction</h5>
                   <p style={{color:'grey'}}>
                   Welcome to abacicount. At abacicount, we are committed to protecting your privacy and safeguarding your personal information. This Privacy Policy outlines how we collect, use, disclose, and manage your information when you use our website and services.</p>
                    <p style={{color:'grey'}}>
By accessing or using our website, you consent to the practices described in this Privacy Policy. Please take a moment to read and understand our policies.
                   </p>

                   <h5 style={{marginBottom:'10px',marginTop:'20px'}}>
                   Information We Collect
                   </h5>

                   <ol >
                    <li><b>Provide Services:</b> <p style={{color:'grey'}}>To provide, maintain, and improve our services and customize your experience.</p></li>
                    <li><b>Communicate with You:</b> <p style={{color:'grey'}}>To send you updates, newsletters, and respond to your inquiries.</p></li>
                    <li><b>Marketing and Promotions: </b > <p style={{color:'grey'}}>To inform you about special offers, promotions, and new features.</p></li>
                    <li><b>Analytics:</b> <p style={{color:'grey'}}>To analyze user behavior, trends, and demographics to improve our website and services.</p></li>

                  </ol>

                  <h5 style={{marginBottom:'10px',marginTop:'20px'}}>
                  Disclosure of Your Information
                   </h5>
                    <p style={{color:'grey'}}>We may share your information in the following circumstances:</p>
                   <ol >
                    <li><b>Service Providers:</b> <p style={{color:'grey'}}>We may engage third-party service providers to assist in providing our services, and they may have access to your information for that purpose.</p></li>
                    <li><b>Legal Requirements:</b> <p style={{color:'grey'}}>We may disclose your information when required by law or in response to valid legal requests.</p></li>
                    <li><b>Business Transfers: </b> <p style={{color:'grey'}}>In the event of a merger, acquisition, or sale of all or part of our assets, your information may be transferred as part of the transaction.</p></li>
                  </ol>

                  <h5 style={{marginBottom:'10px',marginTop:'20px'}}>
                  Security
                   </h5>
                    <p style={{color:'grey'}}>We take reasonable measures to protect your information from unauthorized access, use, or disclosure.</p>
                  
                    <h5 style={{marginBottom:'10px',marginTop:'20px'}}>
                    Children's Privacy
                   </h5>
                    <p style={{color:'grey'}}>Our services are not directed to children under 13, and we do not knowingly collect personal information from them. If you believe that we have inadvertently collected information from a child, please contact us.

                    </p>
                    <h5 style={{marginBottom:'10px',marginTop:'20px'}}>
                    Changes to this Policy

                   </h5>
                    <p style={{color:'grey'}}>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly.</p>
                  
                    <h5 style={{marginBottom:'10px',marginTop:'20px'}}>
                    Contact Us
                   </h5>
                    <p style={{color:'grey'}}>If you have any questions or concerns about this Privacy Policy, please contact us at <a className="line_effect" style={{color:'black'}} href='mailto:mail@abacitechs.com'>mail@abacitechs.com</a>.</p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
      
  )
}

export default Termsandconditions