import React from 'react'
import Layout from './Layout'
import MobileMenu from './MobileMenu'
import Header from './Header'
import Home from '../../pages/Home'
import About from '../../pages/About'
import IndustriesWeServe from '../../pages/IndustriesWeServe'
import Advantages from '../../pages/Advantages'
import Partners from '../../pages/Partners'
import Contact from '../../pages/Contact'
import CopyRight from './CopyRight'
import Mouse from './Mouse'
import ProgressBar from './ProgressBar'

function LandingPage() {
  return (
    <Layout>
    <MobileMenu />
    <Header />
    <Home />
    <About />
    <IndustriesWeServe />
    <Advantages />
    {/* <Projects />
    <Feedback />
    <Blog /> */}
    <Partners />
    <Contact />
    <CopyRight />
    <Mouse />
    <ProgressBar />
  </Layout>
  )
}

export default LandingPage