import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/layouts/LandingPage';
import Termsandconditions from './pages/termsandconditions';


function App() {
  return (
  
    <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/termsandconditions" element={<Termsandconditions />} />
        </Routes>
    </Router>
  );
}

export default App;
