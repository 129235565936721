import sendSvg from '../../src/assets/images/svg/send.svg'
import down_button from '../../src/assets/images/svg/down_button.svg'
import home_video from '../../src/assets/images/hero/peoplewalking.mp4'
const Home = ({ dark }) => {
  return (
    <div className="orido_tm_hero orido_tm_section" id="home">
      <div className="container">
        <div className="content">
          <div className="details">
            <div className="short">
              <h3>
                Welcome to abacicount
                {/* John Smith <img className="svg" src="img/svg/hi.svg" alt="" /> */}
              </h3>
              {/* <span className="job">Visual Designer</span> */}
            </div>
            <div className="text">
              <p>
             Our state-of-the-art people counting solution provides an outstanding way to track foot traffic. By utilizing advanced technology, we enable your business to thoroughly analyze visitor patterns, and ultimately enhance your revenue potential.
              </p>
            </div>
            <div className="buttons">
              <div className="orido_tm_boxed_button">
                <a className="anchor" href="#contact">
                  Get in touch
                  <img className="svg" src={sendSvg} alt="" />
                </a>
              </div>
              {/* <div className="orido_tm_simple_button">
                <a className="line_effect anchor" href="#portfolio">
                  My Works{" "}
                  <img className="svg" src="img/svg/top-arrow.svg" alt="" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="orido_tm_follow">
          <span>Follow me:</span>
          <ul>
            <li>
              <a href="#">
                <img className="svg" src="img/svg/social/be.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img className="svg" src="img/svg/social/dribbble.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img
                  className="svg"
                  src="img/svg/social/instagarm.svg"
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      <div className="orido_tm_down">
        <a className="anchor" href="#about">
          <img
            className="svg"
            src={down_button}
            alt=""
          />
        </a>
      </div>
      <div className="avatar">
        <div className="img">
          <video src={home_video} alt="" autoPlay muted={true} loop={true}  style={{
              transform: "translate(30%, -25%)"}}/>
          {/* <div className="video_button">
            <a
              className="popup-youtube"
              href="https://www.youtube.com/watch?v=7e90gBu4pas"
            >
              <img className="anim_circle" src="img/hero/welcome.png" alt="" />
              <img className="svg" src="img/svg/play.svg" alt="" />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Home;
