import aboutSvg from '../../src/assets/images/svg/element.svg'

const About = () => {
  return (
    <div className="orido_tm_section" id="about">
      <div className="orido_tm_about">
        <div className="about_in">
          {/* <div className="left">
            <div className="box">
              <h3 className="year">10</h3>
              <span className="experience">Years of Experience</span>
              <h3 className="name">John Smith</h3>
            </div>
          </div> */}
          <div className="right">
            <span className="element">
              <img className="svg" src={aboutSvg} alt="" />
            </span>
            <div className="orido_tm_main_title">
              <h3>
                <span>The Importance of a Precise People Counting System</span>
              </h3>
            </div>
            <h3 style={{marginBottom:'15px'}}>Monitor Precise Foot Traffic</h3>
            <div className="text">
             
              <p>
              Our advanced people counting technology goes beyond mere numerical data; it's a tool to revolutionize your malls, retailers, shopping centers, stores, and businesses into vibrant, visitor-centric spaces.
              </p>
              <p style={{marginTop:'10px'}}>
              Abacicount's precise people counting solutions enable you to uncover subtle trends, reveal high-traffic focal points, and strategically design your layout for an unmatched shopping experience.

              </p>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
