import { Fragment, useEffect, useState } from "react";
import { Servicehashtag } from "../components/utilits";
import ServicePopup from "../components/components/popup/ServicePopup";
import toparrow from '../../src/assets/images/svg/top-arrow.svg'
import footfall from '../../src/assets/images/advantages/footfall.png'
import rateanalysis from '../../src/assets/images/advantages/conversionrate.png'
import staffoptimization from '../../src/assets/images/advantages/staffmanagment.jpg'
import marketingefficency from '../../src/assets/images/advantages/marketingefficency.png'


const serviceData = [
  {
    name: "Accurate Footfall Information",
    img: footfall,
    description: [
      "Abacicount's cutting-edge people counting systems revolutionize how businesses monitor and analyze customer traffic. Providing precise, real-time visitor counts with an exceptional accuracy rate of 99%, our technology offers unparalleled insights into customer flow patterns.",
      "This empowers businesses to make informed decisions, optimize operations, and enhance customer experiences.By leveraging Abacicount's advanced solutions, businesses can unlock a deeper understanding of their clientele, ultimately leading to more effective strategies and increased operational efficiency.",
    ],
  },
  {
    name: "Conversion Rate Analysis",
    img: rateanalysis,
    description: [
      "Leverage our AI-driven visitor tracking technology to precisely monitor foot traffic, enabling businesses to effortlessly evaluate conversion rates and refine sales strategies.",
      "With Abacicount's advanced technology, businesses can go beyond surface-level observations and delve into the granular details of customer behavior. This level of precision empowers businesses to refine sales strategies, making them more targeted and effective. By understanding how customers navigate and engage with their spaces, businesses can tailor their offerings and layouts to create an environment that maximizes sales potential.",
    ],
  },
  {
    name: "Staff Optimisation",
    img: staffoptimization,
    description: [
      "Abacicount grants users seamless capability to streamline staffing levels during peak hours with a single click, facilitated by immediate access to foot traffic patterns and advanced customer tracking.",
     ],
  },
  {
    name: "Track Marketing Efficiency",
    img: marketingefficency,
    description: [
      "Evaluate the effectiveness of promotions, marketing initiatives, and promotional offers to more effectively plan future campaigns. Utilizing real-time footfall analytics, you can readily gauge the performance of your marketing endeavors.",
      "By leveraging this wealth of data, businesses gain a comprehensive understanding of customer behavior within their physical spaces. This means you can accurately evaluate the success of various campaigns and initiatives. You'll be able to discern which strategies resonate most with your audience, enabling you to allocate resources more efficiently and refine your marketing efforts for optimal results. "  
    ],
  },
];

const Advantages = () => {
  const [current, setCurrent] = useState(0);
  const [activeData, setActiveData] = useState({});
  const [open, setOpen] = useState(false);
  useEffect(() => {
    Servicehashtag();
  }, [current]);

  return (
    <Fragment>
      <ServicePopup
        open={open}
        close={() => setOpen(false)}
        data={activeData}
      />
      <div className="orido_tm_section" id="service">
        <div className="orido_tm_service">
          <div className="container">
            <div className="orido_tm_main_title">
              <h3>
                <span>
                Advantages of 
                  <br />People Counting Solution
                </span>
              </h3>
            </div>
            <div className="service_list">
              <ul>
                {serviceData.map((service, i) => (
                  <li className={current == i ? "current" : ""} key={i}>
                    <div className="list_inner">
                      <div className="left">
                        <span className="number">{`0${i + 1}`}</span>
                        <h3 className="title">{service.name}</h3>
                      </div>
                      <div className="right">
                        <div className="text">
                          <p>{service.description[0].substring(0, 105)}...</p>
                        </div>
                        <div className="arrow">
                          <img
                            className="svg"
                            src={toparrow}
                            alt=""
                          />
                        </div>
                      </div>
                      <a
                        className="orido_tm_full_link c-pointer"
                        onClick={() => {
                          setActiveData(service);
                          setOpen(true);
                          setCurrent(i);
                        }}
                      />
                      {/* Modalbox Info Start */}
                      {/* <img
                        className="popup_service_image"
                        src="img/service/1.jpg"
                        alt=""
                      /> */}
                    </div>
                  </li>
                ))}
              </ul>
              <span className="ccc" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Advantages;
