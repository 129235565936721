import elements from '../../src/assets/images/svg/elements.svg'
import element2 from '../../src/assets/images/svg/element-2.svg'
import instalogo from '../../src/assets/images/svg/social/instagarm.svg'
import facebooklogo from '../../src/assets/images/svg/social/facebook.svg'


const Contact = () => {
  return (
    <div className="orido_tm_section" id="contact">
      <div className="orido_tm_contact">
        <div className="container">
          <div className="infobox">
            {/* <div className="video_button">
              <a
                className="popup-youtube"
                href="https://www.youtube.com/watch?v=7e90gBu4pas"
              >
                <img
                  className="anim_circle"
                  src="img/contact/video.png"
                  alt=""
                />
                <img className="svg" src="img/svg/play.svg" alt="" />
              </a>
            </div> */}
            <div className="text">
              <h3>Get In Touch</h3>
              <p>
              Connect with our People Counting Solution Expert for more details.
              </p>
            </div>
            {/* <div className="orido_tm_boxed_button">
              <a href="#">
                Say Hello <img className="svg" src="img/svg/send.svg" alt="" />
              </a>
            </div> */}
          </div>
          <div className="connect">
            <div className="left">
              <ul>
                <li>
                  <span className="name">Call:</span>
                  <p>
                    <a className="line_effect" href="tel:+971 55 1997850">
                    +971 4 4269040
                      
                    </a>
                  </p>
                </li>
                <li>
                  <span className="name">Email:</span>
                  <p>
                    <a className="line_effect" href="mailto:admin@abacicount.com">
                      admin@abacicount.com
                    </a>
                  </p>
                </li>
              

              </ul>

              <li>
                  <span className="name" >Address</span>
                  <p style={{marginBottom:'8px',fontWeight:'normal',fontSize:'16px'}}>
                  Dubai Office
                  Abaci FZCO </p>
                  <p style={{marginBottom:'8px',fontWeight:'normal',fontSize:'16px'}}>
                  #001, Building A1, </p>
                  <p style={{marginBottom:'8px',fontWeight:'normal',fontSize:'16px'}}>
                  IFZA Silicon Oasis Freezone,</p>
                  <p style={{marginBottom:'8px',fontWeight:'normal',fontSize:'16px'}}>
                  Dubai, United Arab Emirates
                  </p>
                  <p style={{marginBottom:'8px',fontWeight:'normal',fontSize:'16px'}}>
                    Email : <a className="line_effect" href="mailto:mail@abacitechs.com">mail@abacitechs.com</a>
                  </p>
                </li>
            </div>
            <div className="right">
              <div className="orido_tm_follow">
                <span>Follow us:</span>
                <ul>
                 
                  <li>
                    <a href="https://instagram.com/abacitechs?igshid=MzRlODBiNWFlZA==" target="_blank">
                      <img
                        className="svg"
                        src={instalogo}
                        alt=""
                      />
                    </a>
                  </li>
                  
                  <li>
                    <a href="https://m.facebook.com/p/Abaci-Technologies-Pvt-Ltd-100063893627327/" target="_blank">
                      <img
                        className="svg"
                        src={facebooklogo}
                        alt=""
                      />
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
        <span className="element">
          <img className="svg" src={elements} alt="" />
        </span>
        <span className="element2">
          <img className="svg" src={element2} alt="" />
        </span>
      </div>
    </div>
  );
};
export default Contact;
